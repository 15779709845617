<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Predicciones </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item label="Articulos" icon="hammer-screwdriver">
        <ArticlesListPrediction></ArticlesListPrediction>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import ArticlesListPrediction from "../components/ArticlesListPrediction";
// @ is an alias to /src

export default {
  name: "PredictionsComponentsContainer",
  components: {
    ArticlesListPrediction,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "prediction");
  },
  methods: {},
  async created() {
    let allPromises = [];

    await Promise.all(allPromises);
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
